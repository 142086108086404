<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="183" height="89" viewBox="0 0 183 89" fill="none">
  <path d="M122.21 79.0237L-27.0023 88.2476C-33.5514 81.6984 -37.6045 60.3187 -41.0403 46.2902L65.0986 34.6232L30.7154 0.23997L173.241 -0.00841038C173.241 -0.00841038 182.587 18.6437 182.615 37.2843C182.625 43.8742 84.5525 41.3659 84.5525 41.3659L122.21 79.0237Z" fill="url(#paint0_linear_47_846)"/>
  <defs>
    <linearGradient id="paint0_linear_47_846" x1="-7.27029" y1="75.4526" x2="161.021" y2="15.3485" gradientUnits="userSpaceOnUse">
      <stop stop-color="white" stop-opacity="0"/>
      <stop offset="0.525" stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0"/>
    </linearGradient>
  </defs>
</svg>
</template>