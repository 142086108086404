<template>
<component :is="headerComponent"></component>
<router-view />
<component :is="footerComponent"></component>
</template>

<script>
import TheHeader from "./components/HeaderFooter/HeaderView.vue"
import TheFooter from "./components/HeaderFooter/FooterView.vue"
import TheHeaderEn from "./components/HeaderFooter/HeaderViewEn.vue"
import TheFooterEn from "./components/HeaderFooter/FooterViewEn.vue"

export default {
  components: {
    TheHeader,
    TheFooter,
    TheHeaderEn,
    TheFooterEn
  },
  mounted() {
    window.scrollTo(0, 0);
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.isLoaded = true;
      }
    };
  },
  data() {
    return {
      isLoaded: false,
      headerComponent: TheHeader,
      footerComponent: TheFooter
    };
  },
  watch: {
  '$route'(to) {
    if (to.path.startsWith('/en')) {
      this.headerComponent = TheHeaderEn;
      this.footerComponent = TheFooterEn;
    } else {
      this.headerComponent = TheHeader;
      this.footerComponent = TheFooter;
    }
  }
}
};
</script>

<style>
#app {
  font-family: "Palanquin", sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
body {
  margin: 0em;
}
</style>
