<template>
  <div class="bg-custom-gray relative">
    <div
      class="w-40 h-40 absolute -top-12 -left-8 rotate-[-75deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur hidden 2xl:block"
    ></div>
    <div
      class="w-60 h-60 absolute -top-40 left-112 z-1 rotate-[-120deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur hidden 2xl:block"
    ></div>
    <div
      class="w-40 h-40 absolute -top-24 right-64 rotate-[-75deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur hidden 2xl:block"
    ></div>
    <div
      class="flex justify-around items-center pt-14 pb-8 flex-col lg:flex-row"
    >
      <a
        href="https://asaz.sk/Web_Obchodny_Informacna.pdf"
        target="_blank"
        class="no-underline text-white text-base relative lg:left-4 font-normal mb-4 md:mb-8 lg:mb-0"
        >Protection of personal data</a
      >
      <nav
        class="grid grid-cols-2 md:inline-flex p-1.5 md:items-start md:gap-5 rounded-full flex-col md:flex-row"
      >
        <router-link to="/en">Introduction</router-link>
        <router-link to="/en/services">Services</router-link>
        <router-link to="/en/about">About us</router-link>
        <router-link to="/en/career">Career</router-link>
        <router-link to="/en/contact">Contact</router-link>
      </nav>
      <div
        class="sites flex gap-4 mt-6 relative md:top-10 xl:left-16 lg:top-0 md:mt-0"
      >
        <a href="https://www.facebook.com/ASAZsro"
          ><img
            src="./../../assets/facebook.png"
            alt="facebook"
        /></a>
        <a href="https://www.instagram.com/asaz_s.r.o/"
          ><img
            src="./../../assets/instagram.png"
            alt="instagram"
        /></a>
      </div>
    </div>
    <p class="copyright text-light-gray pt-8 pb-5 m-0 text-center">
      Copyright © 2024 asaz s.r.o
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
nav a {
  display: flex;
  padding: 0.375rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
