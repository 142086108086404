<template>
  <div class="container">
    <header class="bg-cover bg-no-repeat bg-center h-60
    md:h-52 lg:h-88
    xl:h-144 xl:flex xl:justify-center xl:items-center">
      <div class="flex flex-col items-center">
        <h1 class="text-white font-bold leading-32.76 flex justify-center relative z-2 text-4xl top-16 fade-in-bottom
        md:text-7xl md:top-20 lg:text-9xl lg:top-32 xl:top-20">O nás
        </h1>
        <img src="./../assets/OrangeLine.png" alt="" class="relative z-0 w-28 h-4 -bottom-2 slide-right
        md:w-48 md:h-6 md:left-0 md:bottom-0 md:top-16 lg:w-88 lg:h-8 lg:top-26 xl:top-14">
      </div>
    </header>
    <div class="flex justify-center mb-28 relative flex-col items-center
    md:flex-row lg:items-start xl:mb-20">
      <p class=" w-68 text-center top-12 font-normal leading-7.6 relative left-to-right
      md:right-16 lg:text-xl lg:w-116 lg:text-left lg:top-48 xl:text-2xl xl:w-140 xl:top-60 xl:left-4 2xl:left-0">
        Naša firma Asaz je vedúcim poskytovateľom
        služieb v oblasti automatizácie strojov a zariadení a dopravníkovej
        techniky.
        S <b>dlhoročnými <br> skúsenosťami</b> a odbornosťou sme vybudovali silné postavenie na trhu a získali dôveru
        mnohých
        klientov.</p>
      <img src="./../assets/Elektroskrina.png" alt="" class="w-68 relative top-20 slide-in-fwd-center
      md:top-12 lg:w-100 xl:w-140 xl:h-139.6 xl:left-12 2xl:left-40">
      <div
        class="w-47.2 h-47.2 rotate-[150deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute -top-16 right-144 hidden animate-spinIn 2xl:block">
      </div>
    </div>
    <div class="bg-back-orange flex items-center justify-center relative z-2 scale-up-tr lg:h-61 xl:w-208 xl:left-108
    2xl:w-305.6 2xl:left-124">
      <p class="text-white text-center font-normal leading-7.6 p-4 text-xl left-to-right
      lg:text-2xl lg:w-176 xl:w-168"> Venujeme veľkú pozornosť požiadavkám a
        unikátnym potrebám <b>každého klienta</b>,
        aby sme mohli poskytnúť prispôsobené riešenia, ktoré plne vyhovujú ich požiadavkám a očakávaniam.</p>
      <div
        class="hidden 2xl:block w-29.2 h-29.2 rotate-[-165deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute animate-spinIn top-48 right-12">
      </div>
    </div>
    <div class=" bg-bg-comunication flex pt-40 pb-40 relative flex-col items-center -mt-32 justify-center md:flex-row
        xl:-mt-44">
      <img src="./../assets/opravar.png" alt="" class="w-68 slide-in-fwd-center
      md:relative md:right-12 lg:right-20 lg:w-92 xl:w-133.5 xl:h-133.5 xl:right-4 2xl:right-40">
      <div class="hidden animate-spinIn w-34.64 h-34.64 rotate-[-120deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute bottom-40 left-26 
          2xl:block">
      </div>
      <p class="font-normal leading-7.6 text-center top-8 w-72 relative left-to-right
      md:top-0 lg:top-12 lg:text-right lg:w-108 xl:top-12 xl:text-right xl:text-2xl xl:w-160 xl:left-4">V spoločnosti
        si uvedomujeme,
        že <b>komunikácia s klientmi je kľúčová</b> pre optimalizáciu produktivity,
        zlepšenie efektivity a zabezpečenie spoľahlivého prevádzkovania.
        Preto úzko spolupracujeme s našimi klientmi, aby sme pochopili ich jedinečné požiadavky a poskytli
        prispôsobené
        jedinečné riešenia,
        ktoré presne vyhovujú ich potrebám.</p>
      <OrangeThunder class="absolute -bottom-40 lg:right-8 xl:w-61 h-82.5 fade-in-top"></OrangeThunder>
    </div>
    <div class="flex flex-col items-center mt-36
    xl:mt-60">
      <p class="text-center font-normal leading-7.6 text-xl w-68 left-to-right
      md:w-136
      xl:text-2xl xl:w-160">Sme hrdí na našu <b>dlhoročnú spoluprácu s mnohými
          úspešnými podnikmi</b> a na našu schopnosť prispieť k ich
        rastu a úspechu.</p>
        <router-link to="/contact">
          <button
        class="flex items-center gap-3.5 rounded-full bg-back-orange text-white text-2xl justify-center w-60 h-14 border border-back-orange mt-16 btn duration-1000 jello-diagonal-1">
        Napíšte nám
        <img src="./../assets/send-horizontal.svg" alt="">
      </button>
        </router-link>
    </div>
    <div class="relative z-1 bg-machine bg-cover bg-no-repeat bg-center h-40 mt-12
    md:h-64 lg:h-88
    xl:h-146 xl:mt-28"></div>
  </div>
</template>

<script>
import OrangeThunder from "./../components/OrangeThunder.vue"
export default {
components: {
    OrangeThunder
  },
mounted() {
    window.scrollTo(0, 0);
  },
}
</script>

<style  scoped>
header{
  background-image: url(./../assets/Aboutushead.png);
}
.btn:hover,
.btn:focus {
    animation: pulse-169a02ec 1s;
    box-shadow: inset 0 0 0 2em transparent, 0 0 0 1em transparent;
    transform: scale(1.1);
}
</style>