<template>
  <div class="container">
    <header class=" bg-cover bg-no-repeat bg-center h-60
    md:h-64 lg:h-100
    xl:h-144 xl:flex xl:justify-center xl:items-center">
      <div class="flex flex-col items-center">
        <h1 class="text-white font-bold leading-32.76 relative z-2 text-4xl top-18   fade-in-bottom
        md:text-7xl md:top-26  lg:text-9xl lg:left-14 lg:top-40
        xl:left-2 xl:top-12">Kariéra</h1>
        <img src="./../assets/OrangeLine.png" alt="" class="relative z-0 w-32 h-4 -bottom-4  slide-right 
        md:w-64 md:h-6 md:-bottom-24  lg:-bottom-36 lg:w-100 lg:left-14
        xl:-bottom-4 xl:w-104 lg:h-8 xl:left-4">
      </div>
    </header>
    <div class="bg-bg-carrer p-5%">
      <div class="flex justify-center">
        <h2 class="text-text-carrer font-bold md:leading-32.76 relative text-2xl
        md:text-4.5xl lg:pt-12 lg:right-62 left-to-right xl:right-90
         2xl:right-106">Voľné pracovné pozície
        </h2>
      </div>
      <div class="flex flex-col gap-8 pb-28">
        <div class="flex justify-center w-full slide-in-fwd-center">
          <div class="flex items-center justify-center flex-col rounded-3xl bg-white h-144 w-10.5/11.5 shadow-md
          md:w-180 md:h-120 lg:flex-row lg:w-280
          xl:w-317.5 lg:h-56 ">
            <div class="flex items-center justify-center rounded-xl bg-bg-img relative w-28 h-28 -top-4 
              lg:w-31.6 lg:h-31.6 lg:flex lg:right-4 lg:top-0 xl:right-20 2xl:right-32">
              <img src="./../assets/bag.svg" alt="">
            </div>
            <div class="relative text-center xl:r-16 xl:text-start xl:right-8">
              <h2 class="text-text-titlebox font-bold relative text-2xl leading-normal
              md:text-3.5xl xl:leading-152 xl:-top-4">Mechanik Strojov a zariadení </h2>
              <div class="flex gap-3 flex-col items-center relative top-8 
              md:flex-row md:justify-center lg:justify-start xl:items-start xl:top-0 
              xl:w-156">
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5 ">
                    Živnosť</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5">
                    Dohodou</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5 ">
                    Anj/Nej</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                  xl:text-xl  xl:py-2 xl:px-5">
                    <img src="./../assets/map-pin.svg" alt="" class="mr-2"> Nemecko
                  </p>
                </div>
              </div>
            </div>
            <div class="relative top-12 lg:left-4 lg:top-4 xl:right-20 2xl:right-32">
              <p class="text-xl font-normal leading-normal relative 
              xl:-top-4 xl:left-4">Od 23€ 
                <span class="text-gray-300">/ hodinu </span>
              </p>
              <p class="text-gray-300 text-center">+ Záleží od skúseností </p>
              <router-link to="/contact">
                <button
                class="flex items-center rounded-full text-white text-xl w-40 h-12 relative top-4 mb-8 btn duration-1000
              md:mb-12 lg:top-12 xl:top-0 xl:text-2xl justify-center xl:w-44 xl:h-16 xl:mt-4 bg-back-black border border-back-black xl:mb-0">Zistiť
                viac</button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex justify-center w-full slide-in-fwd-center">
          <div class="flex items-center justify-center flex-col rounded-3xl bg-white h-144 w-10.5/11.5 shadow-md
          md:w-180 md:h-120 lg:flex-row lg:w-280
          xl:w-317.5 lg:h-56 ">
            <div class="flex items-center justify-center rounded-xl bg-bg-img relative w-28 h-28 -top-4 
              lg:w-31.6 lg:h-31.6 lg:flex lg:right-4 lg:top-0 xl:right-20 2xl:right-32">
              <img src="./../assets/bag.svg" alt="">
            </div>
            <div class="relative text-center xl:r-16 xl:text-start xl:right-8">
              <h2 class="text-text-titlebox font-bold relative text-2xl leading-normal
              md:text-3.5xl xl:leading-152 xl:-top-4">Elektrikár Strojov a Zariadení </h2>
              <div class="flex gap-3 flex-col items-center relative top-8 
              md:flex-row md:justify-center lg:justify-start xl:items-start xl:top-0 
              xl:w-156">
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5 ">
                    Živnosť</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5">
                    Dohodou</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5 ">
                    Anj/Nej</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                  xl:text-xl  xl:py-2 xl:px-5">
                    <img src="./../assets/map-pin.svg" alt="" class="mr-2">  Nemecko
                  </p>
                </div>
              </div>
            </div>
            <div class="relative top-12 lg:left-4 lg:top-4 xl:right-20 2xl:right-32">
              <p class="text-xl font-normal leading-normal relative 
              xl:-top-4 xl:left-4">Od 23€ 
                <span class="text-gray-300">/ hodinu </span>
              </p>
              <p class="text-gray-300 text-center">+ Záleží od skúseností </p>
              <router-link to="/contact">
                <button
                class="flex items-center rounded-full text-white text-xl w-40 h-12 relative top-4 mb-8 btn duration-1000
              md:mb-12 lg:top-12 xl:top-0 xl:text-2xl justify-center xl:w-44 xl:h-16 xl:mt-4 bg-back-black border border-back-black xl:mb-0">Zistiť
                viac</button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex justify-center w-full slide-in-fwd-center">
          <div class="flex items-center justify-center flex-col rounded-3xl bg-white h-144 w-10.5/11.5 shadow-md
            md:w-180 md:h-120 lg:flex-row lg:w-280
            xl:w-317.5 lg:h-56 ">
            <div class="flex items-center justify-center rounded-xl bg-bg-img relative w-28 h-28 -top-4 
              lg:w-31.6 lg:h-31.6 lg:flex lg:right-4 lg:top-0 xl:right-20 2xl:right-32">
              <img src="./../assets/bag.svg" alt="">
            </div>
            <div class="relative text-center xl:r-16 xl:text-start xl:right-8">
              <h2 class="text-text-titlebox font-bold relative text-2xl leading-normal
              md:text-3.5xl xl:leading-152 xl:-top-4">Mechatronik Strojov a Zariadení </h2>
              <div class="flex gap-3 flex-col items-center relative top-8 
              md:flex-row md:justify-center lg:justify-start xl:items-start xl:top-0 
              xl:w-156">
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5 ">
                    Živnosť</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5">
                    dohodou</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5 ">
                    Anj/Nej</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs  
                  xl:text-xl  xl:py-2 xl:px-5">
                    <img src="./../assets/map-pin.svg" alt="" class="mr-2"> Nemecko
                  </p>
                </div>
              </div>
            </div>
            <div class="relative top-12 lg:left-4 lg:top-4 xl:right-20 2xl:right-32">
              <p class="text-xl font-normal leading-normal relative 
              xl:-top-4 xl:left-4">Od 23€ 
                <span class="text-gray-300">/ hodinu </span>
              </p>
              <p class="text-gray-300 text-center">+ Záleží od skúseností </p>
              <router-link to="/contact">
                <button
                class="flex items-center rounded-full text-white text-xl w-40 h-12 relative top-4 mb-8 btn duration-1000
              md:mb-12 lg:top-12 xl:top-0 xl:text-2xl justify-center xl:w-44 xl:h-16 xl:mt-4 bg-back-black border border-back-black xl:mb-0">Zistiť
                viac</button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex justify-center w-full slide-in-fwd-center">
          <div class="flex items-center justify-center flex-col rounded-3xl bg-white h-144 w-10.5/11.5 shadow-md
            md:w-180 md:h-120 lg:flex-row lg:w-280
            xl:w-317.5 lg:h-56 ">
            <div class="flex items-center justify-center rounded-xl bg-bg-img relative w-28 h-28 -top-4 
              lg:w-31.6 lg:h-31.6 lg:flex lg:right-6 lg:top-0 xl:right-20 2xl:right-32">
              <img src="./../assets/bag.svg" alt="">
            </div>
            <div class="relative text-center xl:r-16 xl:text-start xl:right-8">
              <h2 class="text-text-titlebox font-bold relative text-2xl leading-normal
              md:text-3.5xl xl:leading-152 xl:-top-4">Skladník s VZV </h2>
              <div class="flex gap-3 flex-col items-center relative top-8 
              md:flex-row md:justify-center lg:justify-start xl:items-start xl:top-0 
              xl:w-156">
                <div>
                 <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5 ">
                    Živnosť</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5">
                    dohodou</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5 ">
                    Nej</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs  
                  xl:text-xl  xl:py-2 xl:px-5">
                    <img src="./../assets/map-pin.svg" alt="" class="mr-2"> Nemecko
                  </p>
                </div>
              </div>
            </div>
            <div class="relative top-12 lg:left-4 lg:top-4 xl:right-20 2xl:right-32">
              <p class="text-xl font-normal leading-normal relative 
              xl:-top-4 xl:left-4"> 23€ 
                <span class="text-gray-300">/ hodinu </span>
              </p>
              <router-link to="/contact">
                <button
                class="flex items-center rounded-full text-white text-xl w-40 h-12 relative top-4 mb-8 btn duration-1000
              md:mb-12 lg:top-12 xl:top-0 xl:text-2xl justify-center xl:w-44 xl:h-16 xl:mt-4 bg-back-black border border-back-black xl:mb-0">Zistiť
                viac</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center">
        <h2 class="text-text-carrer font-bold md:leading-32.76 relative text-2xl text-center flex justify-center
        md:text-4.5xl">Ako sa stať súčasťou tímu ?</h2>
        <div class="bg-bg-infbox w-80 h-4 relative bottom-2 z-1 md:bottom-14 md:w-128 lg:bottom-16 lg:h-6 xl:h-8"></div>
      </div>
      <div class="flex flex-col justify-center mb-40 text-center items-center">
        <h3 class="text-xl font-bold md:text-3.5xl mb-8">1. Kontaktujte nás</h3>
        <p class="md:w-176 md:text-xl">Kontaktujte nás cez našu webstránku, kde si prípadne viete vytvoriť vlastný životopis.
              Môžete použiť kontaktný formulár, e-mail alebo reagovať priamo na konkrétnu
              zverejnenú pracovnú ponuku. Touto formou sa dostanete do našej databázy
              vhodných kandidátov. Kontaktovať nás môžete aj telefonicky, prípadne nám zašlite
              Váš profesijný životopis, kópiu živnostenského listu, certifikáty dokazujúce Vaše
              odborné znalosti. Registrácia je nezáväzná a bez poplatkov a prebieha v súlade so
              zákonom č. 428/2002 Z.z. o ochrane osobných údajov.
        </p>
        <h3 class="text-xl font-bold md:text-3.5xl mb-8 mt-52">2. Kontaktujeme Vás</h3>
        <p class="md:w-176 md:text-xl">Podmienkou pre spoluprácu je aktívna živnosť, zodpovedajúce vzdelanie v odbore a
            dostatočné pracovné skúsenosti. V prípade, že budeme mať v ponuke vhodnú prácu
            práve pre Vás, budeme Vás kontaktovať s konkrétnou ponukou. Môžete prísť k nám
            do kancelárie na osobný pohovor alebo sa spojíme telefonicky, resp. prostredníctvom
            e-mailu. Kandidátom sa vždy snažíme poskytnúť to najlepšie riešenie a ponúknuť
            prácu, s ktorou budú spokojní.
        </p>
        <h3 class="text-xl font-bold md:text-3.5xl mb-8 mt-52">3. Dohoda a podpis zmluvy</h3>
        <p class="md:w-176 md:text-xl">V prípade, ak Vás pracovná pozícia oslovila, spĺňate všetky kvalifikačné, resp.
            odborné a osobnostné požiadavky a dohodneme sa na podmienkach vzájomnej
            spolupráce, prichádza na rad podpísanie zmluvy a vybavenie potrebných formalít. Aj
            tento krok je možné vybaviť elektronicky.
        </p>
        <h3 class="text-xl font-bold md:text-3.5xl mb-8 mt-52">4. Nástup na projekt</h3>
        <p class="md:w-176 md:text-xl">Ubytovanie je vždy zabezpečené v blízkosti miesta výkonu práce. Ostáva už len, aby
            ste sa dostavili na určené miesto výkonu práce v určenom čase. Na pracovisku Vás
            bude čakať zástupca firmy nášho klienta, ktorý si Vás prevezme a zadá úlohy.
            Zamestnanci našej spoločnosti sú Vám v prípade akýchkoľvek otázok a problémov
            po celý čas telefonicky k dispozícii.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
}
</script>

<style scoped>
header {
  background-image: url(./../assets/kariera-uvod.png);
}

.btn:hover,
.btn:focus {
  animation: pulse-169a02ec 1s;
  box-shadow: inset 0 0 0 2em transparent, 0 0 0 1em transparent;
  transform: scale(1.1);
}
</style>
