<template>
    <nav class="fixed w-full z-20 top-0 start-0 bg-stone-800 py-4 px-4 lg:py-0 lg:bg-transparent lg:absolute">
        <div class="flex flex-wrap items-center justify-between mx-auto">
            <div class="ml-14 lg:mt-7">
                <router-link to="/"> <img src="./../../assets/asaz-logo.png" alt="Asaz logo" class="animate-slideIn">
                </router-link>
            </div>
            <div class="flex space-x-3 md:space-x-0 rtl:space-x-reverse">
                <button @click="toggleNavbar" type="button"
                    class="inline-flex items-center p-2 w-10 h-10 justify-center text-white text-base  rounded-lg lg:hidden focus:outline-none focus:ring-2 animate-slideIn"
                    aria-expanded="false">
                    <span class="sr-only">Toggle navigation</span>
                    <svg class="w-12 h-12" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 17 14">
                        <path class="line1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" d="M1 1h15" />
                        <path class="line2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" d="M1 7h15" />
                        <path class="line3" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" d="M1 13h15" />
                    </svg>
                </button>
            </div>
            <ul class="p-1.5 items-start gap-5 rounded-full border border-white mt-4 2xl:mr-56
            animate-slideAndGrowIn hidden lg:inline-flex">
                <li><router-link to="/" :class="{ 'active': $route.path === '/' }">Úvod</router-link></li>
                <li><router-link to="/services" :class="{ 'active': $route.path === '/services' }">Služby</router-link>
                </li>
                <li><router-link to="/about" :class="{ 'active': $route.path === '/about' }">O nás</router-link></li>
                <li><router-link to="/career" :class="{ 'active': $route.path === '/career' }" class="relative">Kariera</router-link>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" class="absolute right-64 top-2 animate-ping duration-1000 custom-animation">
                        <circle cx="4" cy="4" r="4" fill="#FF914D"/>
                    </svg>
                </li>
                <li><router-link to="/CV" class="CV"
                        :class="{ 'active': $route.path === '/CV' }">Životopis</router-link>
                </li>
                <li><router-link to="/contact" :class="{ 'active': $route.path === '/contact' }">Kontakt</router-link>
                </li>
            </ul>
            <div :class="{ 'hidden': !isNavbarOpen }" class="items-center justify-between w-full" id="navbar-sticky">
                <ul :class="{ 'hidden': !isNavbarOpen }"
                class="flex flex-col p-4  mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 rtl:space-x-reverse xl:hidden">
                    <li><router-link to="/" class="mb-2" :class="{ 'active': $route.path === '/' }" @click="closeMenu">Úvod</router-link></li>
                    <li><router-link to="/services" class="mb-2" :class="{ 'active': $route.path === '/services' }" @click="closeMenu">Služby</router-link></li>
                    <li><router-link to="/about" class="mb-2" :class="{ 'active': $route.path === '/about' }" @click="closeMenu">O nás</router-link></li>
                    <li class="relative"><router-link to="/career" class="mb-2" :class="{ 'active': $route.path === '/career' }" @click="closeMenu">Kariera</router-link></li>
                    <li><router-link to="/CV" class="CV mb-2" :class="{ 'active': $route.path === '/CV' }" @click="closeMenu">Životopis</router-link></li>
                    <li><router-link to="/contact" class="mb-2" :class="{ 'active': $route.path === '/contact' }" @click="closeMenu">Kontakt</router-link></li>
                </ul>
                <div class="language text-white mt-8 animate-slideIn lg:hidden">
                    <input id="togglerMobile" type="checkbox" class="opacity-0 pointer-events-none touch-none">
                    <label for="togglerMobile" class="flex gap-2 -mt-8 mr-12 cursor-pointer w-full justify-center"
                        @click="toggleDropdown">
                        <img src="./../../assets/Globe.svg" alt="" class="w-6">
                        <p>{{ currentLanguage }}</p>
                        <img src="./../../assets/Caret.svg" alt="" class="w-6">
                    </label>
                    <div class="dropdown flex-col items-center relative" v-if="dropdownVisible">
                        <router-link to="/en" class="no-underline text-white cursor-pointer">EN</router-link>
                    </div>
                </div>
            </div>
            <div class="languagepc text-white mt-8 animate-slideIn hidden lg:block">
                <input id="togglerDesktop" type="checkbox" class="opacity-0 pointer-events-none touch-none">
                <label for="togglerDesktop" class="flex gap-2 -mt-8 mr-12 cursor-pointer" @click="toggleDropdown">
                    <img src="./../../assets/Globe.svg" alt="" class="w-6">
                    <p>{{ currentLanguage }}</p>
                    <img src="./../../assets/Caret.svg" alt="" class="w-6">
                </label>
                <div class="dropdownpc flex-col items-center absolute pl-8" v-if="dropdownVisible">
                    <router-link to="/en" class="no-underline text-white cursor-pointer">EN</router-link>
                </div>
            </div>
        </div>
    </nav>


</template>

<script>
import { gsap } from "gsap";

export default {
    data() {
        return {
            isNavbarOpen: false,
            dropdownVisible: false,
            currentLanguage: 'SK'
        };
    },
    methods: {
        toggleNavbar() {
            this.isNavbarOpen = !this.isNavbarOpen;
            if (this.isNavbarOpen) {
                gsap.to(".line1", { duration: 0.5, attr: { d: "M1 1L15 13" } });
                gsap.to(".line2", { opacity: 0 });
                gsap.to(".line3", { duration: 0.5, attr: { d: "M1 13L15 1" } });
            } else {
                gsap.to(".line1", { duration: 0.5, attr: { d: "M1 1h15" } });
                gsap.to(".line2", { opacity: 1 });
                gsap.to(".line3", { duration: 0.5, attr: { d: "M1 13h15" } });
            }
        },
        toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible;
        },
        closeMenu() {
            this.isNavbarOpen = false;
            gsap.to(".line1", { duration: 0.5, attr: { d: "M1 1h15" } });
            gsap.to(".line2", { opacity: 1 });
            gsap.to(".line3", { duration: 0.5, attr: { d: "M1 13h15" } });
        },
        closeMenuOnResize() {
            if (this.isNavbarOpen) {
                this.closeMenu();
            }
        },
        closeMenuOnPageChange() {
            this.closeMenu();
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.closeMenuOnResize);
        window.removeEventListener('popstate', this.closeMenuOnPageChange);
    },
    mounted() {
        window.addEventListener('resize', this.closeMenuOnResize);
        window.addEventListener('popstate', this.closeMenuOnPageChange);

        const links = document.querySelectorAll('a');
        links.forEach(link => {
            link.addEventListener('click', this.closeMenu);
        });
    },
};
</script>

<style scoped>
ul{
    background: rgba(242, 242, 242, 0.60);
    box-shadow: 0px 4px 14.6px 0px rgba(0, 0, 0, 0.10);
}

ul a {
    display: flex;
    padding: 0.375rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    text-decoration: none;
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

ul a:hover,
.active {
    background-color: rgba(245, 245, 245, 90%);
    border-radius: 1em;
}
.dropdown,
.dropdownpc {
    display: none;
}
.language input:checked~.dropdown,
.languagepc input:checked~.dropdownpc {
    display: flex;
}

/*Animácie*/

@keyframes slideIn {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
.animate-slideIn {
    animation: slideIn 2s ease forwards;
}

@keyframes slideAndGrowIn {
    0% {
        transform: translateY(-100%) scale(0);
        opacity: 0;
    }

    50% {
        transform: translateY(0) scale(1.2);
    }

    100% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

.animate-slideAndGrowIn {
    animation: slideAndGrowIn 3s ease forwards;
}
.custom-animation {
    animation-iteration-count: 5;
}
</style>