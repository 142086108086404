<template>
    <div class="container">
      <header class="relative bg-cover bg-no-repeat -ml-8 h-120 md:h-156 lg:h-178
      xl:h-62">
        <img src="./../../assets/Vector 8.svg" alt=""
          class="w-88 h-118 absolute top-4 right-60 scale-in-hor-right z-10 hidden 2xl:block">
        <div class="bg-back-orange flex justify-center items-center z-2 relative slide-right w-80 h-32 top-40 md:w-132 md:h-44 lg:top-56
        lg:w-170 lg:h-56 xl:top-100 xl:h-44 xl:w-208">
        </div>
        <h1 class="text-white font-bold z-10 absolute fade-in-bottom leading-8 text-3.5xl top-44 left-12 md:leading-12 md:text-5xl md:left-24 lg:top-60
        lg:text-6.5xl lg:leading-16 xl:top-104 xl:left-20">
        Automation of <br> machines and <br class="lg:hidden"> equipment
        </h1>
        <router-link to="/en/about"><button class="w-48 h-12 rounded-full border border-white bg-button-bg text-white text-base relative z-2 scale-in-hor-left top-44 left-16 lg:top-68
            xl:top-116 xl:left-24">See more about us</button></router-link>
        <div
          class="w-242.32 h-258.96 rotate-[-30deg] bg-home-trans border-home-trans  bg-opacity-20 backdrop-blur absolute z-0 -top-56 -left-72 animate-spinIn hidden 2xl:block">
        </div>
        <div
          class="w-210.5 h-210.5 rotate-[-30deg] bg-home-trans  border-home-trans bg-opacity-20 backdrop-blur absolute z-0 -top-108 -right-80 animate-spinIn hidden 2xl:block">
        </div>
      </header>
      <div
        class="w-222.68 h-222.68 rotate-[-30deg] stroke-1 fill-home-trans backdrop-blur stroke-white absolute z-0 top-180 -right-8 animate-spinIn hidden 2xl:block">
      </div>
      <OrangeThunder class="absolute top-184 right-12 fade-in-top hidden 2xl:block"></OrangeThunder>
      <WhiteThunder class="absolute top-180 scale-in-hor-left hidden 2xl:block"></WhiteThunder>
      <div class="p-5% md:p-0">
        <div class="flex relative z-3 flex-col justify-center items-center lg:flex-row lg:mt-8 lg:justify-start lg:items-start
        2xl:ml-16">
          <div class="relative">
            <img src="./../../assets/nepretrzite.png" alt="" class="pt-24 z-2 relative slide-in-fwd-center w-80 md:w-148 lg:max-w-124 lgSSSS:min-w-124 lg:left-4 xl:left-16 
            xl:max-w-2xl xl:min-w-2xl xl:h-140 ">
            <img src="./../../assets/logo-nepretrzite.png" alt="" class="relative bottom-36 left-132 z-2 hidden 2xl:block">
            <div
              class="w-122.12 h-130.12 rotate-[-30deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute -top-4 left-48 animate-spinIn hidden 2xl:block">
            </div>
            <div
              class="w-68 h-68 rotate-[-66.46deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute bottom-2 -left-22 z-10 animate-spinIn hidden 2xl:block">
            </div>
          </div>
          <div class="flex flex-row lg:absolute">
            <h1 class="relative text-back-orange uppercase z-2 font-bold fly-in-text animated-letter text-xl -top-34 right-5 md:text-3.5xl md:-top-62 lg:top-68 lg:left-34 xl:top-76 xl:left-54
              xl:text-4xl" v-for="(letter, index) in text" :key="index">
              {{ letter }}
            </h1>
          </div>
          <div class="w-172 relative flex flex-col items-center text-center lg:text-left xl:ml-10% 2xl:ml-0 2xl:left-56 xl:top-18 xl:items-start left-to-right">
            <h3 class="text-2xl font-bold leading-7.6 mb-8 w-72 md:w-128 left-to-right xl:w-152
            2xl:w-160 ">We push the boundaries of industrial efficiency through innovative automation solutions.</h3>
            <p class="text-base font-normal leading-normal mb-8 w-72 md:w-128 left-to-right xl:w-152
            2xl:w-184 ">Whether it's increasing production capacity or ensuring consistent product quality, our technologies offer you a solution.</p>
            <p class="text-base font-normal leading-normal mb-8 w-72 md:w-128 left-to-right xl:w-152
            2xl:w-184">Imagine that your production knows no breaks. Our systems are designed to ensure uninterrupted, 
            efficient and cost-effective operations. Whether it's sudden changes in the market or unexpected events such as staff sick-outs, 
            our automated solutions allow you to keep up no matter what.</p>
            <p class="text-base font-normal leading-normal w-72 md:w-128 left-to-right xl:w-152
            2xl:w-184">Investing in advanced automation means not only 
              <b>increasing efficiency</b> and <b>reducing operational errors</b>,
              but also giving your employees the ability to focus on more creative and less monotonous tasks.</p>
          </div>
        </div>
        <div class="flex z-2 justify-center xl:justify-end ">
          <div class="bg-back-orange flex flex-col justify-center items-center z-2 w-full h-60 mt-4 text-center scale-up-tr
          xl:w-236 xl:h-36 xl:mr-3 xl:text-left">
            <p class="text-white mt-4 font-bold text-xl w-90% md:w-160 left-to-right
            xl:w-168">Join the industry leaders who are already shaping the future today with our around-the-clock, autonomous systems.</p>
            <img src="./../../assets/asaz-logo.png" alt="logo" class="mt-4 md:mt-4 xl:self-end xl:mr-8 xl:-mt-4 flip-in-diag-2-br">
          </div>
        </div>
        <div class="flex flex-col items-center fullmd:flex-row fullmd:justify-center xl:mt-20">
          <div class="services-textarea flex flex-col items-center justify-center text-center fullmd:relative fullmd:top-32 fullmd:items-center
          xl:items-start xl:top-0 xl:text-left 2xl:right-36">
            <div class="services-title">
              <h1 class="text-6.5xl font-bold leading-152 left-to-right">Services</h1>
              <img src="./../../assets/OrangeLine.png" alt="" class="w-60 h-8 relative -top-10 z-1 m-0 right-0 slide-in-fwd-left
              xl:right-4">
            </div>
              <b class="text-base mb-8 block left-to-right">Our company provides a wide range of services to a variety of industries, including:</b>
              <ul class="list-disc mb-8 ml-8 fullmd:text-left fullmd:ml-0 left-to-right
              xl:ml-4">
                <li>electrical and mechanical <b>installation of automation systems</b></li>
                <li><b>installation and dismantling of </b> automation systems</li>
                <li>manufacture and installation of conveyor technology</li>
                <li>manufacture and installation of electrical switchgear</li>
                <li><b>professional maintenance of systems</b>to ensure reliable and long-term operation</li>
                <li class="pb-8 md:pb-0"> process <b>automation, machine control and building management systems</b></li>
              </ul>
              <p class="w-72 md:w-112 fullmd:w-148 left-to-right
              xl:w-164">Our experts are constantly educated and informed about the latest trends,
               and therefore we are able to provide first-class service with the highest standards of quality and safety.</p>
              <router-link to="/en/services"><button
                class="text-back-orange border border-back-orange bg-white rounded-full w-36 h-12 text-base btn duration-1000 font-extralight mt-8 slide-in-fwd-center">
                View more</button></router-link>
          </div>
            <div class="flex relative flex-col-reverse xl:flex-row">
              <img src="./../../assets/logo-blesk.svg" alt="" class="relative -top-40 left-24 hidden z-2 2xl:block">
              <img src="./../../assets/hala.png" alt="" class="w-80 fullmd:w-100 scroll-from-top
              2xl:w-114.75 2xl:h-110.5">
              <img src="./../../assets/vyrobok.png" alt="" class="absolute z-1 w-80 top-70 fullmd:w-100 fullmd:top-90 scroll-from-top xl:top-32 xl:-right-32
              xl:w-114.75 xl:h-110.5 2xl:top-36 2xl:-right-64">
              <img src="./../../assets/cierne-logo-blesk.svg" alt="" class="absolute -bottom-20 left-160 hidden 2xl:block">
              <div
                class="absolute w-60 h-60 rotate-[-120deg] -right-24 bottom-44 z-1 border-0.5 bg-border-gray bg-opacity-20 backdrop-blur hidden animate-spinIn 2xl:block">
              </div>
              <div
                class="absolute -bottom-16 right-32 w-40.88 h-40.88 border-0.5 bg-border-gray bg-opacity-20 backdrop-blur rotate-[-165deg] hidden animate-spinIn 2xl:block">
              </div>
              <p class="text-text-gray text-6.5xl font-bold leading-152 absolute top-18 -right-54 hidden fade-in-bottom 2xl:block">A-Z</p>
              <OrangeThunder class="absolute top-92 right-48 hidden 2xl:block fade-in-top"></OrangeThunder>
            </div>
        </div>
        <div class="bg bottom-130 left-0 md:bottom-120 fullmd:left-148 xl:left-152 xl:bottom-52"></div>
        <div class="-mt-124 flex flex-col justify-center items-center text-center mb-16
        xl:mb-48">
          <h1 class="text-6.5xl font-bold left-to-right
          xl:leading-152 ">Learn <br class="md:hidden"> more</h1>
          <img src="./../../assets/OrangeLine.png" alt="" class="relative z-1 w-60 left-2 -top-6 md:w-88 md:h-9
          xl:-top-10 xl:w-92 xl:left-2">
            <div class="flex flex-col items-center fullmd:flex-row">
              <div class=" border border-white bg-opacity-20 bg-border-gray p-6.76 pr-4.24 pb-2.16 pl-10.76 relative flex flex-col text-center items-center w-96 md:w-120 fullmd:right-12 left-to-right
                xl:w-148 xl:h-54.24 xl:-left-6 2xl:-left-32 xl:text-left">
                <p class="text-xl font-normal leading-normal mb-8 w-68 md:w-100 xl:w-132 "><b><span class="text-back-orange">Efficient and sustainable</span>
                    technology solutions for your business.</b></p>
                <p class="text-xl font-normal leading-normal w-64 md:w-96 xl:w-132">If you're moving towards using technology to improve efficiency in manufacturing,
                    we're here to help.</p>
              </div>
                <div class="flex mt-4">
                  <img src="./../../assets/Arrows.png" alt="" class="relative h-64 right-0 fullmd:right-8 xl:h-full scroll-from-top
                  xl:right-4">
                  <div class="font-normal leading-normal relative text-base fullmd:right-8
                  xl:text-xl xl:-right-16">
                    <p class="relative left-2 w-60 top-1 md:w-72 xl:w-full xl:text-left scroll-from-top
                    xl:top-1 ">Send us your no-obligation enquiry.</p>
                    <p class="relative left-2 w-56 top-17 md:w-80 md:top-22 xl:text-left scroll-from-top
                    xl:top-22 xl:w-124">Our experts will examine your production processes and prepare a specific offer for you.</p>
                    <p class="relative left-2 w-54 top-26 md:w-84 md:top-38 xl:text-left scroll-from-top
                    xl:top-38 xl:w-148 2xl:w-152">We are here to help you automate your production or parts of it to reach its maximum potential.</p>
                  </div>
                </div>
          </div>
          <router-link to="/en/contact">
            <button class="inline-flex relative px-11 py-4 justify-center items-center gap-2 border rounded-full bg-back-black text-white text-xl mt-36 fullmd:mt-16 jello-diagonal-1 duration-1000 btn
            xl:mt-16 xl:right-8">Contact us</button>
          </router-link>
        </div>
        <div class="relative">
          <img src="./../../assets/vyrobna-linka.png" alt="" class="h-40 md:h-full w-full">
          <div
            class="w-59.92 h-59.92 rotate-[-120deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute -top-32 right-12 z-10 hidden 2xl:block">
          </div>
        </div>
        <div class="flex justify-center mb-40 2xl:mb-80 flex-col lg:flex-row">
          <div class="flex flex-col mt-12 relative text-center items-center xl:left-16
          2xl:-left-4 xl:text-left xl:items-start">
            <div class="flex flex-col mb-12">
              <h2 class="text-5xl font-bold leading-152 mb-4 md:text-6.5xl fade-in-bottom">Contact information</h2>
              <b class="text-2xl leading-normal left-to-right">Asaz, s.r.o.</b>
              <b class="text-2xl leading-normal left-to-right">Automation Solutions A-Z</b>
            </div>
            <div class="flex items-center my-3 left-to-right">
              <img src="./../../assets/mail.svg" alt="">
              <a href="mailto:jan.jancek@asaz.sk" class="text-2xl text-black pl-3 underline">jan.jancek@asaz.sk</a>
            </div>
            <div class="flex items-center my-3 left-to-right">
              <img src="./../../assets/phone-call.svg" alt="">
              <a href="tel:+421 919 020 396" class="text-2xl text-black pl-3 underline">+421 919 020 396</a>
            </div>
            <div class="flex items-center my-3 left-to-right">
              <img src="./../../assets/map-pin.svg" alt="">
              <p class="text-2xl text-black pl-3"> Krivá 56 <br> 027 55 Krivá</p>
            </div>
            <router-link to="/en/contact" >
              <button
              class="flex items-center gap-5 rounded-full bg-back-orange text-white text-2lg justify-center w-56 h-12 border border-back-orange mt-8 btn duration-1000 jello-diagonal-1">
              Contact us
              <img src="./../../assets/send-horizontal.svg" alt="">
            </button>
            </router-link>
          </div>
          <div class="lg:w-207.5 lg:h-146.752 m-auto mt-24 relative w-90% 2xl:m-0 2xl:mt-24">
            <iframe title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.9149187168014!2d19.47985317699427!3d49.2780093710844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4715b99863d879e1%3A0xc5277b3edaaaa067!2sMTS%2C%20spol.%20Ltd.!5e0!3m2!1sen!2ssk!4v1710542592188!5m2!1sen!2ssk"
              class="grayscale xl:mt-12 relative xl:-right-8 w-full h-146.752 lg:w-11/12 scroll-from-top"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>
  
  </template>
  
  <script>
  import OrangeThunder from "./../../components/OrangeThunder.vue"
  import WhiteThunder from "./../../components/WhiteThunder.vue"
  
  export default {
    components: {
      OrangeThunder,
      WhiteThunder
    },
    mounted() {
      window.scrollTo(0, 0);
      this.animateText();
    },
    methods: {
      animateText() {
        const letters = this.text.split('');
        letters.forEach((letter, index) => {
          const span = document.getElementsByClassName('animated-letter')[index];
          span.style.animationDelay = `${index * 0.1}s`;
        });
      }
    },
    data() {
      return {
        text: "constantly"
      };
    }
  }
  </script>
  
  <style  scoped>
  header {
    background-image: url(./../../assets/uvod.png);
  }
  .services-textarea ul li {
    position: relative; 
  }
  .services-textarea ul li::marker {
    font-size: 8px; 
  }
  
  .bg {
    height: 50.31em;
    background: rgba(227, 227, 227, 0.30);
    position: relative;
    z-index: -3;
  }
  </style>