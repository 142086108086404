import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ServicesView from './../views/ServicesView.vue'
import AboutUsView from './../views/AboutUsView.vue'
import CareerView from './../views/CareerView.vue'
import CVView from './../views/CVView.vue'
import ContactView from './../views/ContactView.vue'
import HomeViewEn from './../views/en/HomeViewEn.vue'
import ServicesViewEn from './../views/en/ServicesViewEn.vue'
import AboutUsViewEn from './../views/en/AboutUsViewEn.vue'
import CareerViewEn from './../views/en/CareerViewEn.vue'
import ContactViewEn from './../views/en/ContactViewEn.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/en',
    name: 'en/home',
    component: HomeViewEn
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/en/services',
    name: 'en/services',
    component: ServicesViewEn
  },
  {
    path: '/about',
    name: 'about',
    component: AboutUsView
  },
  {
    path: '/en/about',
    name: 'en/about',
    component: AboutUsViewEn
  },
  {
    path: '/career',
    name: 'career',
    component: CareerView
  },
  {
    path: '/en/career',
    name: 'en/career',
    component: CareerViewEn
  },
  {
    path: '/CV',
    name: 'CV',
    component: CVView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/en/contact',
    name: 'en/contact',
    component: ContactViewEn
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
