<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="152" height="204" viewBox="0 0 152 204" fill="none">
        <path d="M13.4183 112.031L112.405 0C121.667 0 139.651 12.2518 152 19.7419L85.1983 103.043H133.823L33.218 204C33.218 204 13.4206 197.419 0.220009 184.258C-4.44669 179.605 66.6744 112.031 66.6744 112.031H13.4183Z" fill="url(#paint0_linear_47_845)"/>
        <defs>
            <linearGradient id="paint0_linear_47_845" x1="107.5" y1="23" x2="31" y2="184.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" stop-opacity="0"/>
            <stop offset="0.525" stop-color="#FF914D"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>
</template>
