<template>
  <div class="container">
    <header class="flex justify-center items-center bg-cover bg-no-repeat bg-center h-60
    md:h-52 lg:h-88 xl:h-178">
      <div class="flex flex-col items-center">
        <h1 class="text-white  font-bold leading-32.76 flex justify-center relative z-2 text-4xl fade-in-bottom
        md:text-7xl lg:text-9xl lg:right-12 xl:-top-6 xl:left-1 xl:right-0">Služby</h1>
        <img src="./../assets/OrangeLine.png" alt="" class="relative  z-0 w-28 h-4 bottom-14 slide-right
        md:w-56 md:h-8 md:bottom-4 lg:w-96 lg:right-12 xl:bottom-12 xl:w-100 xl:h-10 xl:right-0">
      </div>
    </header>
    <div class="p-5% md:p-0">
      <div>
        <img src="./../assets/sluzby.png" alt="" class="h-24 hidden md:block md:h-auto">
      </div>
      <div class="flex mt-16 justify-center items-center relative mb-12 flex-col text-center
      lg:flex-row xl:items-start">
        <img src="./../assets/obr-planovanie.png" alt="" class="relative w-68 md:w-100 slide-in-fwd-center
        xl:w-147.5 xl:h-147.5 xl:top-28 2xl:right-36">
        <div
          class="w-66.48 h-64.92 rotate-[-77.427deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute top-12 left-112 z-1 hidden 2xl:block animate-spinIn">
        </div>
        <div
          class="w-45.72 h-44.64 rotate-[-49.181deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute bottom-48 left-12 hidden 2xl:block animate-spinIn">
        </div>
        <div
          class="w-59.2 h-57.84 rotate-[-119.41deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute bottom-60 left-124 z-1 hidden 2xl:block animate-spinIn">
        </div>
        <div class="relative top-8 flex flex-col items-center lg:items-start lg:left-12 lg:text-start">
          <h2 class="bg-back-orange text-white text-4.5xl font-bold leading-15.2 w-80 lg:pl-6 mb-4 left-to-right">Plánovanie</h2>
          <div class="flex flex-col text-center items-center lg:items-start lg:text-start">
            <b class="text-2xl leading-15.2 mb-5 block w-56 left-to-right
            md:w-112 xl:mb-0">Dôkladná príprava je kľúčom k úspechu</b>
            <p class="my-6 leading-normal text-xl w-72 left-to-right
            md:w-112 lg:w-120 xl:w-140
            2xl:w-164">Analýza a plánovanie výrobných procesov tvoria základ pre
              efektívne
              fungovanie každej prevádzky.
              Ponúkame integráciu automatizovaných riešení do existujúcich procesov s cieľom dosiahnuť ciele vašej
              spoločnosti.
            </p>
          </div>
          <div class="my-6 flex flex-col text-center items-center lg:items-start lg:text-start">
            <b class="text-2xl leading-15.2 mb-5 block w-56 left-to-right
            md:w-112">Transparentnosť a predvídateľnosť</b>
            <p class="leading-normal text-xl w-72 left-to-right xl:w-140
            md:w-100 lg:w-120
            2xl:w-164">Na začiatku spolupráce kladieme dôraz na pochopenie potenciálu
              transformácie a jej
              finančných aspektov.
              Spolupracujeme na vypracovaní podrobných rozpočtov a vopred diskutujeme o všetkých možných nákladoch, čím
              eliminujeme nepredvídateľné prekvapenia.
            </p>
          </div>
          <div class="my-6 flex flex-col text-center items-center lg:items-start lg:text-start">
            <b class="text-2xl leading-15.2 mb-5 block w-68  left-to-right
            md:w-112">Spolupráca a vizualizácia</b>
            <p class="leading-normal text-xl w-72 lg:w-120 left-to-right xl:w-140
            2xl:w-164">V úzkej spolupráci s vami navrhneme a vypracujeme podrobný koncept
              celého systému, vrátane
              bezpečnostného
              plánu.
              Vďaka simulácii procesných sekvencií v automatizovaných podmienkach získate jasnú predstavu o fungovaní
              systému a možnostiach optimalizácie.
            </p>
          </div>
          <div class="my-6 flex flex-col text-center items-center w-56 lg:items-start lg:text-start">
            <b class="text-2xl leading-15.2 mb-5 block left-to-right
            md:w-112">Neustále hľadanie optimálnych riešení</b>
            <p class="leading-normal text-xl w-72 left-to-right xl:w-140
            md:w-112 lg:w-120
            2xl:w-164">Naším cieľom je poskytnúť vám komplexné riešenie, ktoré bude plne
              vyhovovať vašim požiadavkám a zároveň ponúkať priestor pre neustále zlepšovanie a optimalizáciu.</p>
          </div>
        </div>
      </div>
      <div
        class="flex justify-center bg-br-axis pb-28 flex-col items-center text-center lg:flex-row xl:items-start xl:text-start">
        <div
          class="relative 2xl:right-36 mt-24 flex flex-col justify-center text-center items-center lg:items-start lg:text-start ">
          <h2 class="bg-back-orange text-white text-4.5xl font-bold leading-15.2 w-80 lg:pl-6 mb-4 left-to-right">Montáž</h2>
            <b class="text-2xl leading-15.2 mb-8 block w-72 left-to-right
            md:w-144">Mechanická montáž pre vaše automatizačné potreby</b>
            <p class="xl:w-148 text-xl font-normal leading-7.5 mb-8 w-72 left-to-right
            md:w-144">Náš tím špecialistov zabezpečí realizáciu
              automatizácie.
              Poskytujeme montáž systémov, rekonštrukciu výrobných
              krokov, ako aj premiestnenie strojov či jednotlivých jednotiek.</p>
            <p class="2xl:w-164 text-xl font-normal leading-7.5 mb-8 w-72 left-to-right xl:w-156
            md:w-144">Rýchlo a odborne implementujeme plány
              automatizácie -
              <b>od
                inštalácie oceľových konštrukcií cez montáž
                komponentov až po komplexné zostavy</b>. Špeciálne zváračské práce a úpravy na mieru sú samozrejmosťou.
            </p>
            <p class="2xl:w-156 text-xl font-normal leading-7.5 w-72 left-to-right xl:w-152
            md:w-144"><b>Kľúčom je spoľahlivosť a kvalita.</b> Pravidelnými
              školeniami zaručujeme odborné znalosti, čím optimalizujeme
              vaše procesy.<br> Neváhajte a kontaktujte nás pre riešenia automatizácie z jedného zdroja!</p>
          </div>
          <div class=" relative">
            <img src="./../assets/obr-montaz.png" alt=""
              class="xl:mt-28 mb-8 mt-8 xl:w-147.5 xl:h-147.5 relative z-2 w-68 md:w-100 lg:top-20 lg:left-4 xl:top-0 xl:left-4 2xl:left-0 slide-in-fwd-center">
            <div
              class="w-114 h-111.8 rotate-[-77.427deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute top-23.2 left-56 hidden animate-spinIn 2xl:block">
            </div>
            <div
              class="w-45.72 h-44.64 rotate-[-49.181deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute -bottom-14 -right-16 z-10 hidden animate-spinIn 2xl:block">
            </div>
            <div
              class="w-59.2 h-57.84 rotate-[-119.41deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute -left-18 top-72 animate-spinIn hidden 2xl:block">
            </div>
            <OrangeThunder class="absolute w-63.5 h-85.8 bottom-16 -right-52 z-20 hidden fade-in-top 2xl:block"></OrangeThunder>
          </div>
      </div>
        <div class="flex justify-center items-center mb-40 2xl:mb-80 flex-col lg:flex-row xl:mt-16">
          <div class="relative">
            <img src="./../assets/obr-elektrotechnika.png" alt="" class="relative 2xl:right-36 mt-8 w-68 slide-in-fwd-center xl:right-0
            md:w-100 xl:w-147.5 xl:h-147.5 xl:-mt-8">
            <div
              class="w-99.96 h-97.6 rotate-[-78.371deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute left-22 -top-8 z-1 hidden animate-spinIn 2xl:block">
            </div>
            <div
              class="w-60 h-58.576 rotate-[-78.371deg] border-0.5 bg-border-gray bg-opacity-20 backdrop-blur absolute -bottom-20 -left-52 hidden animate-spinIn 2xl:block">
            </div>
            <img src="./../assets/Vector 7.png" alt="" class="absolute -bottom-20 left-52 hidden 2xl:block fade-in-top">
          </div>
        <div
          class="relative mt-16 2xl:left-12 flex flex-col items-center text-center lg:items-start lg:text-start lg:left-12">
          <h2 class="bg-back-orange text-white text-4.5xl font-bold leading-15.2 w-80 lg:pl-6 mb-4 left-to-right">Elektrotechnika</h2>
          <b class="2xl:w-156 text-2xl leading-15.2 mb-8 block w-72 left-to-right xl:w-152
          md:w-124">Elektrotechnické riešenia bez starostí s Asaz -
            Automation
            Solution A-Z</b>
          <p class="2xl:w-156 text-xl font-normal leading-7.5 mb-8 w-72 left-to-right xl:w-152
          md:w-108 lg:w-120">Nesprávne implementované elektrotechnické
            riešenia?
            Nekvalitná dokumentácia? S nami sa vám to
            nestane!</p>
          <div class="flex flex-col items-center lg:items-start">
            <p class="2xl:w-156 text-xl font-normal leading-7.5 w-72 left-to-right xl:w-152
            md:w-100 lg:w-120">Asaz - Automation Solution A-Z vám ponúka odborné
              služby v
              oblasti elektrotechniky, s ktorými sa môžete
              spoľahnúť na:</p>
            <ul class="list-disc ml-8 flex flex-col items-center mt-4 xl:mt-0 left-to-right">
              <li class="relative xl:w-144 2xl:w-160 text-xl font-normal leading-7.5 w-68
              md:w-100"><b>Štruktúrované riešenia:</b> Naši
                skúsení
                elektrotechnici vám pomôžu s nastavením a inštaláciou
                systémov a strojov v súlade s platnými normami a predpismi.</li>
              <li class="relative xl:w-144 2xl:w-160 text-xl font-normal leading-7.5 w-68
              md:w-100"><b>Široké spektrum služieb:</b> Zvládneme
                hardvérové inžinierstvo v rôznych programoch, montáž a
                zapojenie rozvodných skríň, káblové žľaby, elektroinštaláciu strojov a zariadení a samozrejme aj
                testovanie a meranie podľa DIN a EN noriem.</li>
              <li class="relative xl:w-144 2xl:w-160 text-xl font-normal leading-7.5 w-68
              md:w-100"><b>Kvalitná dokumentácia:</b> Všetky naše
                práce
                sú prehľadne a detailne zdokumentované, aby ste mali
                vždy k dispozícii všetky potrebné informácie.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrangeThunder from "./../components/OrangeThunder.vue"
export default {
 components: {
    OrangeThunder,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
}
</script>

<style scoped>
header {
  background-image: url(./../assets/sluzby-uvod.png);
}

.rely-on ul li {
  position: relative;
  width: 31em;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 152%; 
}
.rely-on ul li:nth-child(1) {
  width: 32em;
}
.rely-on ul li::marker {
  font-size: 8px; 
}
</style>
