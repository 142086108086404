<template>
  <div class="container">
    <header class=" bg-cover bg-no-repeat bg-center h-60
    md:h-64 lg:h-100
    xl:h-144 xl:flex xl:justify-center xl:items-center">
      <div class="flex flex-col items-center">
        <h1 class="text-white font-bold leading-32.76 relative z-2 text-4xl top-18   fade-in-bottom
        md:text-7xl md:top-26  lg:text-9xl lg:left-14 lg:top-40
        xl:left-2 xl:top-12">Career</h1>
        <img src="./../../assets/OrangeLine.png" alt="" class="relative z-0 w-32 h-4 -bottom-4  slide-right 
        md:w-64 md:h-6 md:-bottom-24  lg:-bottom-36 lg:w-100 lg:left-14
        xl:-bottom-4 xl:w-104 lg:h-8 xl:left-4">
      </div>
    </header>
    <div class="bg-bg-carrer p-5%">
      <div class="flex justify-center">
        <h2 class="text-text-carrer font-bold md:leading-32.76 relative text-2xl
        md:text-4.5xl lg:pt-12 lg:right-62 left-to-right xl:right-90
         2xl:right-106">Available job positions</h2>
      </div>
      <div class="flex flex-col gap-8 pb-28">
        <div class="flex justify-center w-full slide-in-fwd-center">
          <div class="flex items-center justify-center flex-col rounded-3xl bg-white h-144 w-10.5/11.5 shadow-md
          md:w-180 md:h-104 lg:flex-row lg:w-228
          xl:w-317.5 lg:h-56 ">
            <div class="flex items-center justify-center rounded-xl bg-bg-img relative w-28 h-28 -top-4 
              lg:w-31.6 lg:h-31.6 lg:flex lg:right-4 lg:top-0 xl:right-20 2xl:right-32">
              <img src="./../../assets/bag.svg" alt="">
            </div>
            <div class="relative text-center xl:r-16 xl:text-start xl:right-8">
              <h2 class="text-text-titlebox font-bold relative text-2xl leading-normal
              md:text-3.5xl xl:leading-152 xl:-top-4">Machine and equipment mechanic</h2>
              <div class="flex gap-3 flex-col items-center relative top-8 
              md:flex-row md:justify-center lg:justify-start xl:items-start xl:top-0 
              xl:w-156">
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5 ">
                    Trade</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5">
                    By agreement </p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                  xl:text-xl  xl:py-2 xl:px-5">
                    <img src="./../../assets/map-pin.svg" alt="" class="mr-2"> Germany
                  </p>
                </div>
              </div>
            </div>
            <div class="relative top-12 lg:left-4 lg:top-4 xl:right-20 2xl:right-32">
              <p class="text-xl font-normal leading-normal relative 
              xl:-top-4 xl:left-4">From €23
                <span class="text-gray-300">/ hour </span>
              </p>
              <p class="text-gray-300 text-center">+ It depends on experience </p>
              <router-link to="/en/contact">
                <button
                class="flex items-center rounded-full text-white text-xl w-40 h-12 relative top-4 mb-8 btn duration-1000
              md:mb-12 lg:top-12 xl:top-0 xl:text-2xl justify-center xl:w-44 xl:h-16 xl:mt-4 bg-back-black border border-back-black xl:mb-0">Find out more</button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex justify-center w-full slide-in-fwd-center">
          <div class="flex items-center justify-center flex-col rounded-3xl bg-white h-144 w-10.5/11.5 shadow-md
          md:w-180 md:h-104 lg:flex-row lg:w-228
          xl:w-317.5 lg:h-56 ">
            <div class="flex items-center justify-center rounded-xl bg-bg-img relative w-28 h-28 -top-4 
              lg:w-31.6 lg:h-31.6 lg:flex lg:right-8 lg:top-0 xl:right-20 2xl:right-32">
              <img src="./../../assets/bag.svg" alt="">
            </div>
            <div class="relative text-center xl:r-16 xl:text-start xl:right-8">
              <h2 class="text-text-titlebox font-bold relative text-2xl leading-normal
              md:text-3.5xl xl:leading-152 xl:-top-4">Machinery and Device Electrician</h2>
              <div class="flex gap-3 flex-col items-center relative top-8 
              md:flex-row md:justify-center lg:justify-start xl:items-start xl:top-0 
              xl:w-156">
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5 ">
                    Trade</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5">
                    By agreement </p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                  xl:text-xl  xl:py-2 xl:px-5">
                    <img src="./../../assets/map-pin.svg" alt="" class="mr-2"> Germany
                  </p>
                </div>
              </div>
            </div>
            <div class="relative top-12 lg:left-4 lg:top-4 xl:right-20 2xl:right-32">
              <p class="text-xl font-normal leading-normal relative 
              xl:-top-4 xl:left-4">From €23
                <span class="text-gray-300">/ hour </span>
              </p>
              <p class="text-gray-300 text-center">+ It depends on experience </p>
              <router-link to="/en/contact">
                <button
                class="flex items-center rounded-full text-white text-xl w-40 h-12 relative top-4 mb-8 btn duration-1000
              md:mb-12 lg:top-12 xl:top-0 xl:text-2xl justify-center xl:w-44 xl:h-16 xl:mt-4 bg-back-black border border-back-black xl:mb-0">Find out more</button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex justify-center w-full slide-in-fwd-center">
          <div class="flex items-center justify-center flex-col rounded-3xl bg-white h-144 w-10.5/11.5 shadow-md
          md:w-180 md:h-104 lg:flex-row lg:w-228
          xl:w-317.5 lg:h-56 ">
            <div class="flex items-center justify-center rounded-xl bg-bg-img relative w-28 h-28 -top-4 
              lg:w-31.6 lg:h-31.6 lg:flex lg:right-4 lg:top-0 xl:right-20 2xl:right-32">
              <img src="./../../assets/bag.svg" alt="">
            </div>
            <div class="relative text-center xl:r-16 xl:text-start xl:right-8">
              <h2 class="text-text-titlebox font-bold relative text-2xl leading-normal
              md:text-3.5xl xl:leading-152 xl:-top-4">Mechatronics Machines and Devices</h2>
              <div class="flex gap-3 flex-col items-center relative top-8 
              md:flex-row md:justify-center lg:justify-start xl:items-start xl:top-0 
              xl:w-156">
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5 ">
                    Trade</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5">
                    By agreement </p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                  xl:text-xl  xl:py-2 xl:px-5">
                    <img src="./../../assets/map-pin.svg" alt="" class="mr-2"> Germany
                  </p>
                </div>
              </div>
            </div>
            <div class="relative top-12 lg:left-4 lg:top-4 xl:right-20 2xl:right-32">
              <p class="text-xl font-normal leading-normal relative 
              xl:-top-4 xl:left-4">From €23
                <span class="text-gray-300">/ hour </span>
              </p>
              <p class="text-gray-300 text-center">+ It depends on experience </p>
              <router-link to="/en/contact">
                <button
                class="flex items-center rounded-full text-white text-xl w-40 h-12 relative top-4 mb-8 btn duration-1000
              md:mb-12 lg:top-12 xl:top-0 xl:text-2xl justify-center xl:w-44 xl:h-16 xl:mt-4 bg-back-black border border-back-black xl:mb-0">Find out more</button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex justify-center w-full slide-in-fwd-center">
          <div class="flex items-center justify-center flex-col rounded-3xl bg-white h-144 w-10.5/11.5 shadow-md
          md:w-180 md:h-104 lg:flex-row lg:w-228
          xl:w-317.5 lg:h-56 ">
            <div class="flex items-center justify-center rounded-xl bg-bg-img relative w-28 h-28 -top-4 
              lg:w-31.6 lg:h-31.6 lg:flex lg:right-22 lg:top-0 2xl:right-32">
              <img src="./../../assets/bag.svg" alt="">
            </div>
            <div class="relative text-center xl:r-16 xl:text-start xl:right-8">
              <h2 class="text-text-titlebox font-bold relative text-2xl leading-normal
              md:text-3.5xl xl:leading-152 xl:-top-4">Warehouseman with VZV</h2>
              <div class="flex gap-3 flex-col items-center relative top-8 
              md:flex-row md:justify-center lg:justify-start xl:items-start xl:top-0 
              xl:w-156">
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5 ">
                    Trade</p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                    xl:text-xl  xl:py-2 xl:px-5">
                    By agreement </p>
                </div>
                <div>
                  <p class="text-black font-normal leading-normal rounded-lg bg-bg-infbox flex py-4 px-8 text-xs
                  xl:text-xl  xl:py-2 xl:px-5">
                    <img src="./../../assets/map-pin.svg" alt="" class="mr-2"> Germany
                  </p>
                </div>
              </div>
            </div>
            <div class="relative top-12 lg:left-4 lg:top-4 xl:right-20 2xl:right-32">
              <p class="text-xl font-normal leading-normal relative 
              xl:-top-4 xl:left-4">From €23
                <span class="text-gray-300">/ hour </span>
              </p>
              <router-link to="/en/contact">
                <button
                class="flex items-center rounded-full text-white text-xl w-40 h-12 relative top-4 mb-8 btn duration-1000
              md:mb-12 lg:top-12 xl:top-0 xl:text-2xl justify-center xl:w-44 xl:h-16 xl:mt-4 bg-back-black border border-back-black xl:mb-0">Find out more</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center">
        <h2 class="text-text-carrer font-bold md:leading-32.76 relative text-2xl text-center flex justify-center
        md:text-4.5xl">How to become a part of our team?</h2>
        <div class="bg-bg-infbox w-80 h-4 relative bottom-2 z-1 md:bottom-14 md:w-128 lg:bottom-16 lg:h-6 xl:h-8"></div>
      </div>
      <div class="flex flex-col justify-center mb-40 text-center items-center">
        <h3 class="text-xl font-bold md:text-3.5xl mb-8">1. Contact us</h3>
        <p class="md:w-176 md:text-xl">Contact us via our website, where you can create your own CV. You can
          use the contact form, e-mail or respond directly to a specific published
          job offer. This way you will get to our database of suitable candidates.
          You can also contact us by phone, or send us your professional CV, a
          copy of your trade license and certificates proving your professional
          knowledge. Registration is nonbinding and free of charge, and it is
          ongoing in accordance with the Data Protection Act no. 428/2002.</p>
        <h3 class="text-xl font-bold md:text-3.5xl mb-8 mt-52">2. We will contact you</h3>
        <p class="md:w-176 md:text-xl">The conditions for our cooperation are your active trade,
          appropriate education in the field and sufficient work experience. If we
          have a suitable job for you, we will contact you with a specific offer. You
          can come to our office for a personal interview, or we will contact you by
          phone or via e-mail. We always try to provide candidates with the best
          solution and offer a job that they will be satisfied with.</p>
        <h3 class="text-xl font-bold md:text-3.5xl mb-8 mt-52">3. Agreement and contract signing</h3>
        <p class="md:w-176 md:text-xl">If the job position appeals to you and you meet all the required
          qualifications, or professional and personal requirements and we will
          agree on the terms of mutual cooperation, it is time to sign the contract
          and complete the necessary formalities. This step can also be completed
          electronically.</p>
        <h3 class="text-xl font-bold md:text-3.5xl mb-8 mt-52">4. Starting the project</h3>
        <p class="md:w-176 md:text-xl">Accommodation is always provided near your workplace. All that remains
          is for you to arrive at the agreed place of work at the agreed time. A
          representative of our client&#39;s company will be waiting for you at the
          workplace and will take you over and assign tasks. The employees of our
          company are always available to you by phone in case of any questions
          or arisen problems.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
}
</script>

<style scoped>
header {
  background-image: url(./../../assets/kariera-uvod.png);
}

.btn:hover,
.btn:focus {
  animation: pulse-169a02ec 1s;
  box-shadow: inset 0 0 0 2em transparent, 0 0 0 1em transparent;
  transform: scale(1.1);
}
</style>
