<template>
  <div class="container">
    <header class="flex bg-cover bg-no-repeat bg-center h-60  flex-col items-center
    md:h-52 lg:h-88 xl:h-92">
      <div class="title">
        <h1 class="text-white font-bold leading-32.76 relative z-2 text-4xl top-16  fade-in-bottom
        md:text-7xl md:top-22 lg:text-9xl lg:top-56 lg:right-60 xl:top-60 2xl:right-100">Contact</h1>
        <img src="./../../assets/OrangeLine.png" alt="" class="relative z-0 w-36 h-4  slide-right
        md:w-68 md:h-6 md:top-16 lg:h-8 lg:w-120 lg:top-50 lg:right-60 xl:top-54 xl:w-124 2xl:right-100">
      </div>
    </header>
    <div class="flex justify-center mb-40 2xl:mb-80 flex-col lg:flex-row">
      <div class="flex flex-col mt-12 relative text-center items-center lg:ml-4 xl:ml-0  xl:left-28
       2xl:left-16 xl:text-left xl:items-start">
        <p class="text-xl font-normal leading-normal mb-12 w-72 left-to-right
        md:w-160">If you are looking for <b>a reliable partner</b> for your machine and plant automation projects, conveyor technology and related services,
         <b>please do not hesitate to contact us.</b> <br class="hidden xl:block">
         We will be happy to provide you with information and solutions that will help you <br class="hidden xl:block">
         achieve your goals and increase the efficiency of your production.</p>
        <div class="flex flex-col mb-12">
          <b class="text-2xl leading-normal left-to-right">Asaz, s.r.o.</b>
          <b class="text-2xl leading-normal left-to-right">Automation Solutions A-Z</b>
          <b class="text-2xl leading-normal left-to-right flex">CRN: <p class="pl-2">52584488</p> </b>
          <b class="text-2xl leading-normal left-to-right flex">TIN: <p class="pl-2">121071887</p> </b>
        </div>
        <div class="flex items-center my-3">
          <img src="./../../assets/mail.svg" alt="">
          <a href="mailto:jan.jancek@asaz.sk" class="text-2xl text-black pl-3 underline left-to-right">jan.jancek@asaz.sk</a>
        </div>
        <div class="flex items-center my-3">
          <img src="./../../assets/phone-call.svg" alt="">
          <a href="tel:+421 919 020 396" class="text-2xl text-black pl-3 underline left-to-right">+421 919 020 396</a>
        </div>
        <div class="flex items-center my-3">
          <img src="./../../assets/map-pin.svg" alt="">
          <p class="text-2xl text-black pl-3 left-to-right"> Krivá 56 <br> 027 55 Krivá</p>
        </div>
        <a href="mailto:jan.jancek@asaz.sk">
        <button 
          class="flex items-center gap-5 rounded-full bg-back-orange text-white text-2lg justify-center w-56 h-12 border border-back-orange mt-8 jello-diagonal-1 btn duration-1000">
          Contact us
          <img src="./../../assets/send-horizontal.svg" alt="">
        </button>
        </a>
      </div>
      <div class="lg:w-207.5 lg:h-146.752 m-auto mt-24 relative w-11/12 2xl:m-0 2xl:mt-24 2xl:left-16">
        <iframe title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.9149187168014!2d19.47985317699427!3d49.2780093710844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4715b99863d879e1%3A0xc5277b3edaaaa067!2sMTS%2C%20spol.%20Ltd.!5e0!3m2!1sen!2ssk!4v1710542592188!5m2!1sen!2ssk"
          class="grayscale mt-12 relative xl:-right-8 w-full h-146.752 lg:w-11/12 scroll-from-top"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
}
</script>

<style scoped>
header {
  background-image: url(./../../assets/kontakt-uvod.png);
}
</style>