<template>
  <div class="container">
    <header
      class="flex justify-center items-center bg-cover bg-no-repeat bg-center h-60 md:h-88 lg:h-88 xl:h-178"
    >
      <div class="title">
        <h1
          class="text-white font-bold leading-32.76 relative z-2 text-4xl fade-in-bottom md:text-7xl md:-top-4 lg:text-8xl xl:text-9xl lg:-top-6 xl:-top-14"
        >
          Životopis
        </h1>
        <img
          src="./../assets/OrangeLine.png"
          alt=""
          class="relative z-0 w-40 h-4 bottom-14 slide-right md:w-80 md:bottom-8 lg:w-108 lg:bottom-12 xl:w-140 lg:h-8 xl:bottom-20"
        />
      </div>
    </header>
    <div
      class="flex justify-center -mt-12 mb-40 2xl:mb-80 w-full lg:-mt-28 xl:-mt-70"
    >
      <form
        id="cvForm"
        @submit.prevent="odosliFormular"
        enctype="multipart/form-data"
        class="flex p-2.5 gap-2.5 w-11/11.5 rounded-2xl border bg-gray-100 border-cv-border flex-col items-center md:w-180 scroll-from-top xl:w-212.76"
      >
        <div class="flex mt-10 flex-col md:flex-row w-90% md:w-auto">
          <div class="md:mr-8 md:ml-0 mx-5% w-full">
            <p class="text-cv-text text-base font-medium leading-6">Meno</p>
            <input
              type="text"
              name="meno"
              id="name"
              v-model="personalInformation.name"
              placeholder="Ján"
              class="p-3 rounded bg-white border border-white h-12 text-base text-black w-90% md:w-56"
            />
          </div>
          <div class="md:mx-0 mx-5% w-full">
            <p class="text-cv-text text-base font-medium leading-6">
              Priezvisko
            </p>
            <input
              type="text"
              name="priezvisko"
              id="surname"
              v-model="personalInformation.surname"
              placeholder="Novák"
              class="p-3 rounded bg-white border border-white h-12 text-base text-black w-90% md:w-56"
            />
          </div>
        </div>
        <div class="flex flex-col md:flex-row w-90% md:w-auto">
          <div class="md:mr-8 md:ml-0 mx-5% w-full">
            <p class="text-cv-text text-base font-medium leading-6">
              Dátum narodenia
            </p>
            <input
              type="text"
              name="datum-narodenia"
              id="dateOfBirth"
              v-model="personalInformation.dateOfBirth"
              placeholder="01.01.2000"
              class="p-3 rounded bg-white border border-white h-12 text-base text-black w-90% md:w-56"
            />
          </div>
          <div class="pernament-address md:mx-0 mx-5% w-full">
            <p class="text-cv-text text-base font-medium leading-6">
              Adresa trvalého bydliska
            </p>
            <input
              type="text"
              name="trvala-adresa"
              id="address"
              v-model="personalInformation.address"
              placeholder="Jána Trnku 28, Bratislava"
              class="p-3 rounded bg-white border border-white h-12 text-base text-black w-90% md:w-56"
            />
          </div>
        </div>
        <div class="mail w-90% ml-9% md:ml-0 md:w-auto">
          <p class="text-cv-text text-base font-medium leading-6">Email</p>
          <input
            type="email"
            name="mail"
            id="email"
            v-model="personalInformation.email"
            placeholder="mail@mail.com"
            class="p-3 rounded bg-white border border-white h-12 text-base text-black w-90% md:w-120"
          />
        </div>
        <div class="tel-num w-90% ml-9% md:ml-0 md:w-auto">
          <p class="text-cv-text text-base font-medium leading-6">
            Telefónne číslo
          </p>
          <input
            type="tel"
            name="tel-cislo"
            id="phone"
            v-model="personalInformation.phone"
            placeholder="0900 000 000"
            class="p-3 rounded bg-white border border-white h-12 text-base text-black w-90% md:w-120"
          />
        </div>
        <div
          class="h-0.5 my-2 bg-input-text z-2 opacity-20 w-90% md:w-140"
        ></div>
        <div
          v-for="(eduEntry, index) in educationEntries"
          :key="index"
          class="flex flex-col w-90% ml-9% md:ml-0 md:w-auto"
        >
          <p class="text-cv-text text-base font-medium leading-6">
            Typ Vzdelania
          </p>
          <select
            v-model="eduEntry.type"
            class="p-3 rounded-md bg-white border border-white h-12 text-base text-input-text cursor-pointer w-90% md:w-120"
          >
            <option disabled value="">Vyberte možnosť</option>
            <option value="zakladne">Základné</option>
            <option value="stredne">Stredné</option>
            <option value="vysokoskolske">Vysokoškolské</option>
          </select>
          <div
            v-if="eduEntry.type"
            id="name"
            class="flex flex-col items-center w-90% md:w-auto"
          >
            <input
              type="text"
              placeholder="Názov školy/univerzity"
              v-model="eduEntry.name"
              class="p-3 rounded-md bg-white border border-white h-12 text-base text-black my-4 w-full md:w-120"
            />
            <div class="flex flex-col md:flex-row w-full md:w-auto">
              <input
                type="number"
                placeholder="Od roku"
                v-model="eduEntry.yearFrom"
                class="p-3 rounded-md bg-white border border-white h-12 text-base text-black cursor-pointer my-4 w-full md:w-58 md:mr-4"
              />
              <input
                type="number"
                placeholder="Do roku"
                v-model="eduEntry.yearTo"
                class="p-3 rounded-md bg-white border border-white h-12 text-base text-black cursor-pointer my-4 w-full md:w-58"
              />
            </div>
            <input
              type="text"
              placeholder="Odbor"
              v-model="eduEntry.subject"
              class="p-3 rounded-md bg-white border border-white h-12 text-base text-black my-4 w-full md:w-120"
            />
            <div class="flex">
              <button @click.prevent="addEntry" class="text-input-text mr-4">
                Pridať ďalší záznam
              </button>
              <button
                @click.prevent="removeEntry(index)"
                v-show="educationEntries.length > 1"
                class="text-input-text"
              >
                Odstrániť tento záznam
              </button>
            </div>
          </div>
        </div>
        <div
          class="h-0.5 my-2 bg-input-text z-2 opacity-20 w-90% md:w-140"
        ></div>
        <div class="w-90% ml-9% md:ml-0 md:w-auto">
          <div
            v-for="(experience, index) in experiences"
            :key="index"
            class="flex flex-col"
          >
            <p class="text-cv-text text-base font-medium leading-6">
              Skusenosti
            </p>
            <input
              id="experience"
              type="text"
              v-model="experience.name"
              placeholder="Názov spoločnosti"
              class="p-3 rounded-md bg-white border border-white h-12 text-base text-black cursor-pointer mb-4 w-90% md:w-120"
            />
            <div class="flex flex-col md:flex-row">
              <div class="w-full">
                <p class="text-cv-text text-base font-medium leading-6">Od</p>
                <flat-pickr
                  v-model="experience.dateFrom"
                  :config="datePickerConfig"
                  id="dateFrom"
                  placeholder="Dátum začiatku"
                  class="p-3 rounded-md bg-white border border-white h-12 text-base text-black cursor-pointer mb-4 w-90% md:w-58 md:mr-4"
                ></flat-pickr>
              </div>
              <div class="w-full">
                <p class="text-cv-text text-base font-medium leading-6">Do</p>
                <flat-pickr
                  v-model="experience.dateTo"
                  :config="datePickerConfig"
                  placeholder="Dátum konca"
                  id="dateTo"
                  class="p-3 rounded-md bg-white border border-white h-12 text-base text-black cursor-pointer mb-4 w-90% md:w-58"
                ></flat-pickr>
              </div>
            </div>
            <textarea
              v-model="experience.subject"
              placeholder="Pozícia"
              id="subject"
              class="p-3 rounded-md bg-white border border-white max-h-12 min-h-12 text-base text-black cursor-pointer mb-4 w-90% md:w-120"
            ></textarea>
          </div>
          <div class="flex justify-center w-90% md:w-auto">
            <button @click.prevent="addExperience" class="text-input-text mr-4">
              Pridať pracovnú skúsenosť
            </button>
            <button
              @click.prevent="removeExperience(index)"
              v-show="showRemoveExperienceButton"
              class="text-input-text"
            >
              Odstrániť pracovnú skúsenosť
            </button>
          </div>
        </div>
        <div
          class="h-0.5 my-2 bg-input-text z-2 opacity-20 w-90% md:w-140"
        ></div>
        <div class="work w-90% ml-9% md:ml-0 md:w-auto">
          <p class="text-cv-text text-base font-medium leading-6">
            Špecializácia
          </p>
          <input
            type="text"
            name=""
            id="specialization"
            v-model="specialization"
            placeholder="Elektrikár"
            class="p-3 rounded bg-white border border-white h-12 text-base text-black w-90% md:w-120"
          />
        </div>
        <div class="w-90% ml-9% md:ml-0 md:w-auto">
          <p class="text-cv-text text-base font-medium leading-6">
            Certifikáty
          </p>
          <textarea
            placeholder="§21"
            id="cert"
            v-model="cert"
            class="p-3 rounded-md bg-white border border-white max-h-12 min-h-12 text-base text-black cursor-pointer mb-4 w-90% md:w-120"
          ></textarea>
        </div>
        <div
          class="h-0.5 my-2 bg-input-text z-2 w-90% opacity-20 md:w-140"
        ></div>
        <div class="w-90% ml-9% md:ml-0 md:w-auto">
          <div
            v-for="(language, index) in languages"
            :key="index"
            class="w-full"
          >
            <div
              class="flex w-auto items-start md:items-center mb-4 flex-col md:flex-row"
            >
              <input
                type="text"
                v-model="language.name"
                id="lang"
                placeholder="Angličtina"
                class="p-3 rounded bg-white border border-white h-12 text-base text-black w-90% md:w-56 md:mr-8"
              />
              <select
                v-model="language.level"
                class="p-3 rounded bg-white border border-white h-12 text-base text-input-text w-90% mt-4 md:w-56 md:mt-0"
              >
                <option value="" disabled selected>Vyberte možnosť</option>
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
                <option value="C1">C1</option>
                <option value="C2">C2</option>
              </select>
            </div>
          </div>
          <div class="flex justify-center">
            <button @click.prevent="addLanguage" class="text-input-text mr-4">
              Pridať jazyk
            </button>
            <button
              @click.prevent="removeLanguage(index)"
              v-show="showRemoveLanguageButton"
              class="text-input-text"
            >
              Odstrániť jazyk
            </button>
          </div>
        </div>
        <div
          v-for="(license, index) in licenses"
          :key="index"
          class="w-90% ml-9% md:ml-0 md:w-auto"
        >
          <div class="flex flex-col">
            <p class="text-cv-text text-base font-medium leading-6">
              Vodičský preukaz
            </p>
            <select
              v-model="license.level"
              id="drivingLicence"
              class="p-3 rounded bg-white border border-white h-12 text-base text-input-text w-90% md:w-120"
            >
              <option value="" disabled selected>Vyberte úroveň</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
              <option value="E">E</option>
              <option value="T">T</option>
            </select>
          </div>
        </div>
        <div class="flex justify-center">
          <button @click.prevent="addLicense" class="text-input-text mr-4">
            Pridať vodičský preukaz
          </button>
          <button
            @click.prevent="removeLicense(index)"
            v-show="showRemoveLicenseButton"
            class="text-input-text"
          >
            Odstrániť vodičský preukaz
          </button>
        </div>
        <div
          class="h-0.5 my-2 bg-input-text z-2 opacity-20 w-90% md:w-140"
        ></div>
        <div class="flex flex-col relative mt-7 items-center md:left-48">
          <label for="profilePictureInput" class="flex cursor-pointer">
            <img
              src="./../assets/file-plus 1.svg"
              alt="Vybrať súbor"
              class="file-icon"
            />
            <p class="text-cv-text text-base font-medium leading-6 ml-4">
              Vložiť fotku
            </p>
          </label>
          <input
            id="profilePictureInput"
            type="file"
            @change="handleProfilePictureUpload"
            hidden
            accept="image/png"
          />
          <p v-if="profilePictureName" class="text-input-text text-center">
            {{ profilePictureName }}
          </p>
          <img
            v-if="profilePictureUrl"
            :src="profilePictureUrl"
            alt="Nahratá fotka"
            class="mt-4 rounded-lg w-20"
          />
          <button
            v-if="profilePictureName"
            @click="removeProfilePicture"
            class="text-input-text mt-4"
          >
            Odstrániť fotku
          </button>
        </div>
        <div class="flex flex-col relative mt-7 md:left-48 items-center">
          <label for="attachment" class="flex cursor-pointer">
            <img
              src="./../assets/file-plus 1.svg"
              alt="Vybrať súbor"
              class="file-icon"
            />
            <p class="text-cv-text text-base font-medium leading-6 ml-4">
              pridať prílohu
            </p>
          </label>
          <input
            id="attachment"
            type="file"
            @change="handleAttachmentUpload"
            multiple
            hidden
            accept="image/png"
          />

          <div v-if="attachment.length > 0" class="flex flex-col items-center">
            <h3 class="text-cv-text mt-4">Nahrané súbory:</h3>
            <ul>
              <!-- Výpis názvov nahraných súborov -->
              <li
                v-for="file in attachment"
                :key="file.name"
                class="text-input-text"
              >
                {{ file.name }} - <a :href="file.url" target="_blank"></a>
              </li>
            </ul>
          </div>
          <button
            v-if="attachment.length > 0"
            @click="removeAttachment"
            class="text-input-text mt-4"
          >
            Odstrániť prílohu
          </button>
        </div>
        <div class="flex items-center w-132 mt-8 flex-col md:flex-row md:ml-12">
          <input
            type="checkbox"
            name=""
            id=""
            class="w-6 h-6 border border-input-text"
            required
          />
          <p class="ml-2 text-input-text text-sm font-normal leading-5">
            súhlasim s
            <span class="text-back-orange">Podmienkami spracovania údajov</span>
          </p>
        </div>
        <input type="hidden" id="postman" name="postman" />
        <div class="btn">
          <button
            type="submit"
            class="flex items-center gap-5 rounded-full bg-back-orange text-2xl text-white justify-center w-60 h-16 border-back-orange mt-12"
          >
            Poslať Životopis
            <img src="./../assets/send-horizontal.svg" alt="" />
          </button>
        </div>
        <div class="mt-8 mb-12">
          <img src="./../assets/black-logo.svg" alt="" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    flatPickr,
  },
  data() {
    return {
      experiences: [{ selectedOption: "", showOptions: false,  
        name: "",
        dateFrom: "",
        dateTo: "",
        subject: "", }],
      experience: {
        name: "",
        dateFrom: "",
        dateTo: "",
        subject: "",
      },
      languages: [{ name: "", level: "", showOptions: false }],
      licenses: [{ type: "", level: "", showOptions: false }],
      fileName: null,
      profilePictureUrl: "",
      datePickerConfig: {
        altInput: true,
        altFormat: "d.m.Y", // Formát pre zobrazenie v textovom poli
        dateFormat: "d.m.Y", // Formát pre interné použitie
      },
      personalInformation: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        dateOfBirth: "",
        address: "",
      },
      specialization: "",
      educationEntries: [
        { type: "", yearFrom: "", yearTo: "", name: "", subject: "" },
      ],
      education: {
        school: [],
        university: [],
      },
      cert: "",
      drivingLicence: "",
      lang: [
        []
      ],
      attachment: "",
      profilePicture: "",
    };
  },
  methods: {
    addEntry() {
      this.educationEntries.push({
        type: "",
        yearFrom: "",
        yearTo: "",
        name: "",
        subject: "",
      });
    },
    removeEntry(index) {
      this.educationEntries.splice(index, 1);
      this.updateEducation();
    },
    updateEducation() {
      this.education.school = this.educationEntries.filter(
        (e) => e.type === "zakladne" || e.type === "stredne"
      );
      this.education.university = this.educationEntries.filter(
        (e) => e.type === "vysokoskolske"
      );
    },
    addExperience() {
      this.experiences.push({ selectedOption: "", showOptions: false });
    },
    removeExperience(index) {
      this.experiences.splice(index, 1);
    },
    addLanguage() {
      this.languages.push({ name: "", level: "", showOptions: false });
    },
    removeLanguage(index) {
      this.languages.splice(index, 1);
    },
    addLicense() {
      const newLicense = { level: "" }; // Vytvoriť nový vodičský preukaz so základnými údajmi
      this.licenses.push(newLicense); // Pridať nový vodičský preukaz do poľa všetkých preukazov
      this.drivingLicence = ""; // Resetovať dáta o vodičskom preukaze
    },
    removeLicense(index) {
      this.licenses.splice(index, 1); // Odstrániť vodičský preukaz z poľa na základe indexu
    },
    handleProfilePictureUpload(event) {
  const profilePicture = Array.from(event.target.files)[0];
  if (!profilePicture) {
    alert("Žiaden súbor nebol vybratý.");
    return;
  }
  const allowedTypes = ["image/png"];
  if (!allowedTypes.includes(profilePicture.type)) {
    alert("Súbor nie je podporovaný. Vyberte PNG súbor.");
    return;
  }
  this.profilePictureName = profilePicture.name;
  const reader = new FileReader();
  reader.onload = () => {
    this.profilePictureUrl = reader.result; // Base64 URL of the image
    this.profilePicture = this.profilePictureName + '|' + this.profilePictureUrl; // Concatenate name and URL
  };
  reader.readAsDataURL(profilePicture);
},
    removeProfilePicture() {
      this.profilePictureName = "";
      this.profilePictureUrl = ""; // Reset hodnoty
      const input = document.getElementById("profilePictureInput");
      if (input) {
        input.value = ""; // Fyzický reset input elementu
      } else {
        console.error("Profile picture input not found.");
      }
    },
    handleAttachmentUpload(event) {
    const files = Array.from(event.target.files); // Prevedie FileList na pole
    if (files.length === 0) {
      return;
    }

    const allowedTypes = ["image/png"];
    let allFilesValid = true;
    let tempAttachments = [];

    // Počítač prechádza každým súborom a kontroluje typ
    for (const file of files) {
      if (!allowedTypes.includes(file.type)) {
        allFilesValid = false;
        break;
      }

      // Čítanie súborov a pridávanie ich URL do dočasného poľa
      const reader = new FileReader();
      reader.onload = (e) => {
        tempAttachments.push({
          name: file.name,
          url: e.target.result,
        });

        // Ak sme na poslednom súbore a všetky sú validné, aktualizujeme hlavné pole
        if (tempAttachments.length === files.length && allFilesValid) {
          this.attachment = tempAttachments;
        }
      };
      reader.readAsDataURL(file);
    }

    // Ak nejaké súbory neboli platné, resetujeme zoznam
    if (!allFilesValid || tempAttachments.length !== files.length) {
      this.attachment = []; // inicializujeme ako prázdne pole
    }
  },
    removeAttachment() {
      this.attachment = []; // Reset zoznamu súborov
      document.getElementById("attachment").value = null; // Reset inputu
    },
    filterAndMapEntries(entries, type) {
      return entries.filter(entry => entry.type === type).map(entry => ({
        yearFrom: entry.yearFrom,
        yearTo: entry.yearTo,
        name: entry.name,
        subject: entry.subject
      }));
     },
    odosliFormular() {
      console.log("idem odoslať formulár");
      const axios = require("axios");
      const FormData = require("form-data");
      let data = new FormData();
      // nejako takto si popridávaš veci čo tam chceš pridať, podľa jurovho kódu
      // všetky dáta pridávaj cez v-model tak sa to robí najlepšie, každé táta ktoré chceš ukladať mu nechaj v model ktorý tu pridáš
      // data.append("type", "PRICE");
      // data.append("value", "15.99");
      // data.append("value", "15.99");
      //alebo daj len
      //data = JSON.Stringify(vsetkyVeci); možno takto to pojde skor nwm


      const postman = {}

      let drivingLicence = this.licenses
        .map((license) => license.level)
        .join(",");
        postman["drivingLicence"] = drivingLicence;

        let experiencesForSubmission = this.experiences.map(experience => ({
          name: experience.name,
          dateFrom: experience.dateFrom,
          dateTo: experience.dateTo,
          subject: experience.subject
        }));
      postman["experience"] = experiencesForSubmission;

      let lang = this.languages.map(language => [language.name, language.level]);
      postman["lang"] = lang;

      const attachmentArray = [];

      // Prechádzanie cez každý objekt v this.attachment
      for (let i = 0; i < this.attachment.length; i++) {
        // Pridanie URL adresy súboru do countable array
        attachmentArray.push(this.attachment[i].url);
      }

      // Priradenie countable array do postman["attachment"]
      postman["attachment"] = attachmentArray;

      let personalInformation = {
        name: this.personalInformation.name,
        surname: this.personalInformation.surname,
        email: this.personalInformation.email,
        phone: this.personalInformation.phone,
        dateOfBirth: this.personalInformation.dateOfBirth,
        address: this.personalInformation.address,
      };
      postman["personalInformation"] = personalInformation;

      postman["specialization"] = this.specialization;

      const schoolZakladne = this.filterAndMapEntries(this.educationEntries, "zakladne");

      // Filtrácia a mapovanie záznamov pre "stredne"
      const schoolStredne = this.filterAndMapEntries(this.educationEntries, "stredne");

      // Kombinovanie výsledkov do jedného poľa
      const school = schoolZakladne.concat(schoolStredne);
      const university = this.filterAndMapEntries(this.educationEntries, "vysokoskolske");

      let education = { school, university };
      postman["education"] = education;

      postman["cert"] = this.cert;

      const profilePictureName = this.profilePictureName || "";
      const profilePictureUrl = this.profilePictureUrl || "";
      const profilePicture = profilePictureName + "" + profilePictureUrl;
      postman["profilePicture"] = profilePicture;

      console.log(postman);
      data.append('postman', JSON.stringify(postman));
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        //url kde má juraj na to script (!musíš nahrať na web a tak testovať!!!!!!!!)
        url: "https://asaz.sk/resume/create.php",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
        responseType: 'blob',
      };
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          const url = URL.createObjectURL(response.data); // Vytvorenie URL z odpovede
          window.open(url, '_blank'); // Otvorenie PDF v novom okne
          window.addEventListener('unload', () => URL.revokeObjectURL(url)); // Revoke URL
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    mounted() {
      // Pri načítaní komponentu sa stránka posunie na začiatok
      window.scrollTo(0, 0);
    },
  },
  watch: {
    educationEntries: {
      handler() {
        this.updateEducation();
      },
      deep: true,
    },
  },
  computed: {
    showRemoveExperienceButton() {
      return this.experiences.length > 1;
    },
    showRemoveLanguageButton() {
      return this.languages.length > 1;
    },
    showRemoveLicenseButton() {
      return this.licenses.length > 1;
    },
  },
};
</script>

<style scoped>
header {
  background-image: url(./../assets/zivotopis-uvod.png);
}

#attachment,
#profilePicture {
  display: none;
}
</style>
